@import "styles/mixins";

.landing {
	gap: 80px;
	.section_1 {
		min-height: 528px;
		.col {
			.background {
				background: var(--avena-accent-color-tertiary);
				position: absolute;
				margin: -16px 0px;
				left: 0px;
				right: 0px;
				height: 528px;

				.background_image {
					position: absolute;
					right: 136px;
				}
			}
			.text_box {
				margin-top: 93px;
				z-index: 0;
				@include mobile {
					z-index: 0;
				}
				gap: 17px;
				color: var(--avena-text-color-white);

				.header {
					font: var(--avena-font-header-xl-bold);
				}

				.body {
					font: var(--avena-font-xl-medium);
				}
			}
		}
	}

	.banner_section {
		gap: 15px !important;
		color: var(--avena-text-color-white);
		.first_column {
			height: -webkit-fill-available;
			position: relative;
			border-radius: 10px;
			.background {
				border-radius: 10px;
				top: 0px;
				height: 100%;
				object-fit: cover;
				width: 100%;
				left: 0px;
				position: absolute;
			}
			.background2 {
				border-radius: 10px;
				background: linear-gradient(
					267.67deg,
					rgba(89, 126, 247, 0.9) 7.9%,
					rgba(202, 97, 162, 0.9) 112.59%
				);
				top: 0px;
				height: 100%;
				width: 100%;
				left: 0px;
				position: absolute;
			}
			border-radius: 10px;
			padding: 35px 45px;
			.flex_container {
				z-index: 0;
				.header {
					font: 600 34px/51px var(--avena-font-family);
					margin: 30px 0px 10px 0px;
				}
			}
			.body {
				z-index: 0;
				font: 400 20px/30px var(--avena-font-family);
			}
			.button {
				z-index: 0;
				width: fit-content;
				padding: 12px 32px;
				background: white;
				color: var(--avena-accent-color-tertiary);
				margin-top: 43px;
			}
		}
		.second_column {
			@extend .first_column;
			.background2 {
				background: linear-gradient(
					267.67deg,
					rgba(15, 86, 161, 0.9) 7.9%,
					rgba(16, 153, 231, 0.9) 112.59%
				);
			}
		}
	}

	.section_2 {
		.first_column {
			justify-content: space-between;
			height: 100%;
			// margin-right: 100px;
			.header {
				font: var(--avena-font-header-l-bold);
				color: var(--avena-accent-color-quaternary);
			}
			.body {
				font: var(--avena-font-l-regular);
				color: var(--avena-text-color-primary);
				margin-bottom: 30px;
			}
			.button {
				padding: 12px 32px;
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				width: fit-content;
			}
		}
		.second_column {
			.header {
				color: var(--avena-accent-color-primary);
				font: var(--avena-font-xxl-semibold);
				margin-bottom: 17px;
			}
			.row {
				width: 100%;
				gap: 36px;
				.col {
					width: 100%;
				}
				.divider {
					border-bottom: 1px solid #d7d7d7;
					width: 100%;
				}
				.content_container {
					gap: 20px;
					@extend .divider;
					// margin: 20px 0px;
					padding: 14px 0px;
					align-items: center;
					font: var(--avena-font-m-regular);
					color: var(--avena-text-color-primary);
				}
			}
		}
	}

	.section_3 {
		border-radius: 20px;
		align-items: center !important;
		background: var(--avena-accent-color-primary);
		padding: 40px;
		color: var(--avena-text-color-white);

		.first_column {
			height: 100%;
			gap: 25px;
			padding-right: 50px;
			justify-content: space-between;
			.header {
				font: var(--avena-font-header-l-bold);
			}
			.body {
				font: var(--avena-font-l-regular);
			}
			.button {
				color: var(--avena-text-color-white);
				border-color: var(--avena-text-color-white);
				width: fit-content;
			}
		}
		.second_column {
			.image {
				width: 100%;
				height: auto;
			}
		}
	}

	.section_4 {
		gap: 29px;

		.row {
			gap: 20px !important;
			.card {
				gap: 10px;
				padding-right: 47px;
				.circle {
					width: 27px;
					height: 27px;
					border-radius: 50%;
					background: var(--avena-accent-color-secondary);
					@include flexCenter();
				}
				.sub_header {
					color: var(--avena-accent-color-secondary);
					font: 500 18px/27px var(--avena-font-family);
				}
				.body {
					margin-top: 10px;
					font: 400 16px/24px var(--avena-font-family);
					color: var(--avena-accent-color-primary);
				}
			}
		}
		.divider {
			margin-top: 67px;
			border-top: 1px solid #d7d7d7;
			width: 100%;
		}
		.header {
			color: var(--avena-accent-color-secondary);
			font: 600 28px/42px var(--avena-font-family);
		}
	}

	.portfolio {
		.button {
			padding: 6px;
			cursor: pointer;
			position: absolute;
			margin-top: 314px;
			@include flexCenter();
		}
		.button_prev {
			@extend .button;
			left: calc(((100vw - 1368px) / 2) - 63px);
		}
		.button_next {
			@extend .button;
			right: calc(((100vw - 1368px) / 2) - 63px);
		}
		.carousel {
			.portfolio_header {
				color: var(--avena-accent-color-quaternary);
				font: 600 42px/63px var(--avena-font-family);
			}
			.item {
				padding: 80px 0px;
				border-bottom: 1px solid #d7d7d7;
				.icon_container {
					.icon {
						margin-bottom: 50px;
					}
					.icon_subtext {
						margin-left: 18px;
						font-weight: 600;
						font-size: 20px;
						line-height: 30px;
						color: #002f5e;
					}
				}

				.arrow_icon {
					margin-top: 5px;
					padding-right: 19px;
				}

				.vstack {
					.header {
						font: var(--avena-font-l-medium);
						margin-bottom: 15px;
					}
					.body {
						font: var(--avena-font-s-regular);
						padding-right: 13px;
					}
					.hr {
						border-bottom: 1px solid #d7d7d7;
						margin: 30px 0px;
					}
					.technology_text {
						font: var(--avena-font-l-regular);
						margin-bottom: 11px;
					}
					.technology_container {
						gap: 8px;
						flex-wrap: wrap;
					}
					.technology {
						display: flex;
						align-items: center;
						text-align: center;
						border: 1px solid #7c7b7b;
						max-height: 77px;
						width: fit-content;
						border-radius: 20px;
						padding: 5px 20px;
						.technology_font {
							font: 400 10px/15px var(--avena-font-family);
						}
					}
					.visit {
						margin-top: 50px;
						width: fit-content;
					}
				}

				.image_container {
					img {
						width: 100%;
						max-width: 842px;
						align-self: flex-end;
						height: 100%;
					}
				}
			}
		}
	}

	.section_5 {
		.sectionHeader {
			margin-bottom: 16px;
			.title {
				color: var(--avena-accent-color-quaternary);
				font-weight: 500;
				font-size: 42px;
				line-height: 63px;
				margin-bottom: 13px;
			}

			.description {
				color: #404040;
				font-weight: 300;
				font-size: 28px;
				line-height: 42px;
			}
		}

		.textContainer {
			color: #404040;
			font-weight: 300;
			font-size: 18px;
			line-height: 27px;

			.leftColumn {
				margin-right: 50px;
			}
		}
	}

	.section_6 {
		.text_container {
			margin-bottom: 24px;
			align-items: center;

			.section_header {
				font: 600 42px/63px var(--avena-font-family);
				color: var(--avena-accent-color-primary);
			}
		}

		.navigation {
			width: 100%;
			justify-content: space-between;
			div {
				// @include flexCenter();
				width: 100%;
				height: 80px;
				border-right: 1px solid #d7d7d7;
				border-top: 1px solid #d7d7d7;
				cursor: pointer;
			}

			.selected {
				background: var(--avena-accent-color-tertiary);
				color: white;
				border-right: 1px solid var(--avena-accent-color-tertiary);
				border-top: 1px solid var(--avena-accent-color-tertiary);
			}
		}

		.card_container {
			padding: 30px;
			background-color: #e6f0fd;
			border-radius: 10px;
			overflow: hidden;

			.row {
				gap: 20px !important;
				align-items: stretch !important;

				.image_container {
					background: var(--avena-text-color-link);
					padding: 36px 14px;

					.image {
						height: 100%;
						width: 100%;
					}
				}

				.video_container {
					min-height: 500px;
				}

				.content {
					background: white;
					padding: 20px;
					border-radius: 10px;
					display: flex;
					justify-content: flex-start;
					gap: 30px;
					.hstack {
						gap: 20px;
						.option_header {
							font: var(--avena-font-l-semibold);
						}

						.body_text {
							font: var(--avena-font-s-regular);
						}
					}
				}

				.get_in_touch_with_us {
					@include flexCenter();
					height: 100%;
					width: 100%;
					.header {
						cursor: pointer;
						color: var(--avena-accent-color-primary);
						font-weight: 600;
						font-size: 42px;
						line-height: 63px;
					}
				}
			}
		}
	}

	.section_7 {
		.divider {
			margin-bottom: 62px;
			border-bottom: 1px solid #d7d7d7;
			width: 100%;
		}
		.sectionHeader {
			font-weight: 600;
			font-size: 42px;
			line-height: 63px;
			color: var(--avena-accent-color-quaternary);
			margin-bottom: 40px;
		}

		.cardContainer {
			gap: 37px !important;
			.card {
				border-radius: 6px;
				border: 1px solid #d7d7d7;
				height: 100%;
				.cardImage {
					border-radius: 6px 6px 0px 0px;
					min-height: 475px;
					@include flexCenter();
					overflow: hidden;
					img {
						width: 100%;
						height: auto;
					}
				}

				.cardBody {
					padding: 30px 40px 65px 60px;
					.cardTitle {
						font-weight: 500;
						font-size: 30px;
						line-height: 34px;
						color: #404040;
						margin-bottom: 30px;
					}
					.cardDescription {
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: #404040;
					}
				}
			}
		}
	}

	.section_8 {
		.divider {
			margin-left: 120px;
			border-top: 1px solid #d7d7d7;
			width: 100%;
		}

		.sectionHeader {
			font-weight: 600;
			font-size: 42px;
			line-height: 63px;
			color: var(--avena-accent-color-quaternary);
			margin-bottom: 35px;
		}

		.rowContainer:hover {
			background-color: #f9fbfd;
		}
		.rowContainer {
			padding: 2px;
			.row {
				.numberText {
					display: flex;
					flex-direction: row;
					justify-content: center;
					width: 50px;
					margin-right: 50px;

					color: #0066cc;
					font-weight: 500;
					font-size: 80px;
					line-height: 120px;
				}

				.contentContainer {
					/* Greyscale/Stroke */
					border-bottom: 1px solid #d7d7d7;
					height: 100%;

					.titleText {
						display: flex;
						flex-direction: column;
						justify-content: center;
						height: 100%;

						color: #404040;
						font-weight: 500;
						font-size: 18px;
						line-height: 27px;
					}

					.descriptionText {
						display: flex;
						flex-direction: column;
						justify-content: center;
						height: 100%;

						color: #404040;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
					}
				}
			}
		}
	}

	.section_9 {
		.sectionHeader {
			margin-bottom: 20px;
			font-weight: 600;
			font-size: 30px;
			line-height: 112.5%;
			font: 600 30px/112.5% var(--avena-font-family);
			color: var(--avena-accent-color-quaternary);
		}

		.cardContainer {
			gap: 36px !important;
			border-radius: 6px;
			.card {
				color: #ffffff;
				background: #004f9d;
				height: 78%;
				padding: 40px;
				border-radius: 6px;

				.circledNumber {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 50px;
					height: 50px;
					background: #ffffff;
					border-radius: 50%;
					margin-bottom: 37px;

					.text {
						padding: 8px 20px;
						color: #004f9d;
						font-weight: 600;
						font-size: 30px;
						line-height: 34px;
					}
				}

				.cardHeader {
					margin-bottom: 17px;
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
				}

				.cardDescription {
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}
}

@include mobile {
	.landing {
		gap: 30px;
		.section_1 {
			min-height: 522px;
			padding: 0px 13px;
			.col {
				.background {
					.background_image {
						width: 100%;
						height: 328px;
						position: initial;
						margin-top: 210px;
						// display: none;
						object-fit: cover;
					}
				}
				.text_box {
					margin-top: 33px;
					gap: 9px;
					padding: 0px 24px 0px 0px;
					.header {
						font: 600 26px/36px var(--avena-font-family);
					}

					.body {
						padding-right: 32px;
						font: var(--avena-font-s-regular);
					}
				}
			}
		}

		.banner_section {
			padding: 0px 13px;
			gap: 20px !important;
			margin: 0px;
			.first_column {
				width: -webkit-fill-available;
				padding: 20px 14px;
				.flex_container {
					flex-direction: row;
					align-items: center;
					gap: 30px;
					margin-bottom: 16px;
					.icon {
						width: 69px;
						height: auto;
					}
					.header {
						font: 600 20px/30px var(--avena-font-family);
						margin: 0px;
						padding-right: 90px;
					}
				}
				.body {
					font: var(--avena-font-s-regular);
				}
				.button {
					z-index: 0;
					width: 100%;
					padding: 12px 32px;
					background: white;
					color: var(--avena-accent-color-tertiary);
					margin-top: 21px;
				}
			}
			.second_column {
				@extend .first_column;
				.background2 {
					background: linear-gradient(
						267.67deg,
						rgba(15, 86, 161, 0.9) 7.9%,
						rgba(16, 153, 231, 0.9) 112.59%
					);
				}
			}
		}

		.section_2 {
			padding: 30px 13px 0px 13px;
			margin: 0px;
			background: #eff7ff;
			.first_column {
				.header {
					font: 600 34px/51px var(--avena-font-family);
					margin-bottom: 20px;
				}
				.body {
					font-weight: 400;
					font-size: 14px;
					line-height: 174%;
					margin-bottom: 30px;
				}
				.button {
					padding: 12px 32px;
					font-weight: 500;
					font-size: 20px;
					line-height: 30px;
					width: fit-content;
				}
			}
			.second_column {
				margin-top: 14px;
				width: 100%;
				.header {
					margin-bottom: 17px;
				}
				.row {
					flex-direction: column;
					gap: 0px;
					.divider {
						width: calc(100vw - 32px);
						border-bottom: 1px solid #d7d7d7;
					}
				}
			}
		}

		.section_3 {
			border-radius: 10px;
			padding: 30px 20px;
			margin: 0px 13px;

			.first_column {
				gap: 24px;
				padding-right: 0px;
				.header {
					font: 600 34px/51px var(--avena-font-family);
				}
				.body {
					font: 400 12px/174% var(--avena-font-family);
				}
				.button {
					color: var(--avena-text-color-white);
					border-color: var(--avena-text-color-white);
					width: fit-content;
				}
			}
			.second_column {
				width: 100%;
				.image {
					height: auto;
				}
			}
		}

		.section_4 {
			margin: 0px 13px;
			gap: 20px;
			.header {
				font: 600 20px/30px var(--avena-font-family);
			}
			.row {
				gap: 20px !important;
				.card {
					gap: 10px;
					padding-right: 47px;
					flex-direction: row;
					.circle {
						min-width: 27px;
					}
					.text_container {
						.body {
							margin-top: 6px;
							font: 400 14px/21px var(--avena-font-family);
						}
					}
				}
			}
		}

		.portfolio {
			.button {
				z-index: 1;
				padding: 6px;
				cursor: pointer;
				position: absolute;
				margin-top: calc(63px + (100vw - 26px) * (336 / 844));
				@include flexCenter();
			}
			.button_prev {
				@extend .button;
				left: 14px;
			}
			.button_next {
				@extend .button;
				right: 8px;
			}
			.carousel {
				height: 100%;
				background: #eff7ff;
				padding: 30px 13px 13px 13px;
				margin-bottom: -60px;
				.portfolio_header {
					font: 600 34px/51px var(--avena-font-family);
				}
				.item {
					padding: 0px;
					border-bottom: none;
					.icon {
						margin-bottom: 22px;
					}

					.arrow_icon {
						margin-top: 5px;
						padding-right: 19px;
					}

					.image_container {
						height: calc((100vw - 26px) / 1.27);
						position: relative;
						margin-bottom: 30px;
						img {
							border-radius: 10px;
						}
					}
					.vstack {
						margin-bottom: 40px;
						.header {
							font: var(--avena-font-l-medium);
							margin-bottom: 15px;
						}
						.body {
							font: var(--avena-font-s-regular);
							padding-right: 13px;
						}
						.hr {
							width: 100%;
							margin: 20px 0px;
						}
						.technology_text {
							font: var(--avena-font-l-regular);
							margin-bottom: 11px;
						}
						.technology_container {
							padding-bottom: 20px;
							border-bottom: 1px solid #d7d7d7;
							gap: 8px;
							flex-wrap: wrap;
						}
						.technology {
							display: flex;
							align-items: center;
							text-align: center;
							border: 1px solid #7c7b7b;
							max-height: 77px;
							width: fit-content;
							border-radius: 20px;
							padding: 5px 20px;
							.technology_font {
								font: 400 10px/15px var(--avena-font-family);
							}
						}
						.visit {
							margin-top: 20px;
							width: fit-content;
						}
					}
				}
				.portfolio_header {
					margin-bottom: 20px;
				}
			}
		}

		.section_5 {
			margin:42px 13px 0px 13px;

			.divider {
				display: none;
			}

			.sectionHeader {
				margin-bottom: 0px;
				.title {
					font: 600 34px/51px var(--avena-font-family);
					margin-bottom: 20px;
				}

				.description {
					font: var(--avena-font-m-medium);
				}
			}

			.textContainer {
				font: var(--avena-font-s-regular);

				.leftColumn {
					margin-right: 50px;
				}
			}
		}

		.section_6 {
			margin: 0px 13px;
			.text_container {
				margin-bottom: 16px !important;

				.section_header {
					font: 600 34px/51px var(--avena-font-family) ,!important;
				}

				.section_sub_text {
					font: var(--avena-font-m-medium) !important;
				}
			}

			.card_container {
				padding: 0px;
				background-color: inherit;
				border-radius: 0px;
				.row {
					.image_container {
						background: var(--avena-text-color-link);
						padding: 36px 14px;

						.image {
							height: 100%;
							width: 100%;
						}
					}

					.video_container {
						min-height: 500px;
					}

					.content {
						background: grey;
						padding: 20px;
						border-radius: 10px;
						display: flex;
						justify-content: flex-start;
						gap: 30px;
						background: #f2f6f9;
						border-radius: 10px;
						.hstack {
							flex-direction: column;
							gap: 20px;
							.option_header {
								font: var(--avena-font-l-semibold);
							}

							.body_text {
								font: var(--avena-font-s-regular);
							}
						}
					}
				}
			}
		}

		.section_7 {
			padding: 30px 13px 20px 13px;
			.divider {
				display: none;
			}
			background: #F2F6F9;
			.sectionHeader{
				font: 600 34px/51px var(--avena-font-family);
			}

			.cardContainer {
				.card {
					.cardImage {
						min-height: auto;
						width: 100%;
						img {
							padding: 37px 46px;
							width: 100%;
							height: auto;
						}
					}
					.cardBody {
						padding: 20px;
						.cardTitle {
							margin-bottom: 10px;
							font: 600 20px/30px var(--avena-font-family);
						}
						.cardDescription {
							font: var(--avena-font-s-regular);
						}
					}
				}
			}
		}

		.section_8 {
			padding: 0px 13px;
			background: inherit;

			.divider {
				margin-left: 48px;
			}

			.sectionHeader {
				padding-right: 124px;
				margin-bottom: 20px;
				font: 600 34px/51px var(--avena-font-family);
			}

			.rowContainer:hover {
				background-color: #f9fbfd;
			}
			.rowContainer {
				padding: 2px;
				.row {
					gap: 0px !important;
					flex-direction: row;
					.numberText {
						min-width: 48px;
						margin-right: 0px;
						font: 500 40px/60px var(--avena-font-family);
					}

					.contentContainer {
						/* Greyscale/Stroke */
						border-bottom: 1px solid #d7d7d7;
						height: 100%;

						.titleText {
							line-height: 58px;
						}

						.descriptionText {
							margin-bottom: 20px;
						}
					}
				}
			}
		}

		.section_9 {
			padding: 30px 13px;
			background: #F2F6F9;
			.sectionHeader {
				margin-bottom: 10px;
				font: 600 34px/51px var(--avena-font-family);
			}

			.cardContainer {
				gap: 20px !important;
				.card {
					padding: 30px;

					.circledNumber {
						margin-bottom: 20px;
						width: 42px;
						height: 42px;
					}
				}
			}
		}

		.section_10 {
			margin: 0px 13px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1350px) {
	.landing {
		padding: 0px 14px;
		.section_1 {
			min-height: 400px;
			@include flexCenter();
			.col {
				.background {
					height: 400px;
					.background_image {
						display: none;
					}
				}
				.text_box {
					text-align: center;
					width: 80vw;
				}
			}
		}
		.portfolio {
			.carousel {
				.image_container {
					height: 100%;
					justify-content: center;
					img {
						align-self: center;
						height: auto;
					}
				}
				@media only screen and (min-width: 768px) and (max-width: 1023px) {
					.image_container {
						img {
							margin-top: 30px;
							align-self: center;
						}
					}
				}
			}
		}

		.section_7 {
			.cardContainer {
				gap: 25px !important;
				.card {
					.cardBody {
						padding: 30px;
					}
				}
			}
		}

		.section_9 {
			.cardContainer {
				gap: 25px !important;
			}
		}
	}
}
